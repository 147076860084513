import { Avatar, Button, Col, Dropdown, Icon, Menu, Modal, Popover, Row, Tooltip } from 'antd';
import ColorContext from 'context/ColorContext';
import firebase from 'firebase/app';
import 'firebase/storage';
import moment from 'moment';
import React, { MutableRefObject, useEffect, useState, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { v4 as uuid } from 'uuid';
import {
  statsAction,
  updateFirebaseAuthToken as updateFirebaseAuthTokenAction,
} from '../../../store/actions/authActions';
import { transcript } from '../../../store/actions/recordingAction';
import {
  // addPageComment,
  deleteFromVideoStorage,
  resolvePage,
} from '../../../store/actions/pageActions';
import { sendMessageToSlack } from '../../../store/actions/slackIntegrationAction';
import { IFeedback } from '../../../types/pageTypes';
import { mobileCheck, sendMessageToIframe, trackAnalytics } from '../../../utils';
import UploadFileLogic from '../../shared/UploadFileLogic';
import { CommentInfoModal } from '../CommentInfoModal';
// import DragAndDropZone from '../../shared/DragAndDropZone';
import RenderVideosFile from '../RenderVideos';
// import { ImagePreview } from '../ImagePreview';
import UploadModal from '../UploadModal';
import AudioPlayer from './AudioPlayer';
import ChatMessageInput from './ChatMessageInput';
import { AudioSvg, DeleteIcon, NavigatorIcon, ScreenSvg, VideoSvg } from './ChatSvgs';
import ChatVideoPlayer from './ChatVideoPlayer';
import ImagePreviewComponent from './ImagePreviewComponent';
import RecordAudioNew from './RecordAudioNew';
import RecordVideo from './RecordVideo';
import SideDrawerComponent from './SideDrawerComponent';
import MediaPermissionModal from '../../recording/MediaPermissionModal';

const isMobile = mobileCheck();
const itemRefs: any = {};

export interface IFeedbackModelProps {
  pages: any;
  auth: any;
  profile: any;
  iframe: any;
  // addPageFeedback: any;
  resolvePageFeedback: any;
  onClose?: any;
  projectID: string;
  pageID: string;
  circlePointerID: string;
  direction: string;
  resolved: string;
  currentShowingMessageid: string;
  clearShowingMessageid: any;
  isChatWindow?: boolean;
  isPublicChatWindow?: boolean;
  firebaseAuthToken: string;
  isWriteAccess: boolean;
  // chatData: any;
  addMessageInThread: (data: any, newDocumentId: any) => void;
  match?: any;
  chatId: string;
  clearOldChat?: boolean;
  setClearOldChat?: any;
  history: any;
  organization: any;
  updateFirebaseAuthToken(token: string): void;
  isInterviewCanvas?: boolean;
}

const FeedbackModel: (props: IFeedbackModelProps) => JSX.Element = ({
  pages,
  auth,
  profile,
  iframe,
  // addPageFeedback,
  resolvePageFeedback,
  onClose,
  projectID,
  pageID,
  circlePointerID,
  direction,
  resolved,
  currentShowingMessageid,
  clearShowingMessageid,
  isChatWindow,
  isPublicChatWindow,
  firebaseAuthToken,
  isWriteAccess = false,
  updateFirebaseAuthToken,
  // chatData,
  addMessageInThread,
  chatId,
  clearOldChat,
  setClearOldChat,
  // match,
  history,
  organization,
  isInterviewCanvas = false,
}) => {
  const trackAnalyticsPrefix = isPublicChatWindow ? 'Public User - ' : '';
  let urlRefs: any = '';
  const imgRef = React.useRef() as MutableRefObject<HTMLImageElement>;
  const chatContainerRef: any = React.createRef();
  // const feedbacks: IFeedback[] = [];
  let inProgress: IFeedback[] = [];
  const { fullName = '', photoURL = '' } = profile;
  let userQuota: any = { storageLimit: 0 };
  if (profile.isManagedUserQuotaInOrganization) {
    userQuota =
      organization &&
        organization.selectOrganizationDetails &&
        organization.selectOrganizationDetails.userQuota
        ? organization.selectOrganizationDetails.userQuota
        : profile.userQuota;
  } else {
    userQuota = profile.userQuota;
  }
  userQuota = userQuota ? userQuota : { storageLimit: 0 };
  const [value, setValue] = React.useState('');
  const [updateInput, setUpdateInput] = React.useState(false);
  const [selectedImage, setImage] = React.useState('');
  const [showPreview, setPreview] = React.useState(false);
  const [scrollIntoView, setScrollIntoView] = React.useState(true);
  const [userID, setUserID] = React.useState();
  // const [sendingImage, setSendingImage] = React.useState(false);
  const [allowScrollToBootom, setAllowScrollToBootom] = React.useState(false);
  // const [isRecordingModal, setIsRecordingModal] = React.useState(false);
  const [showCommentInfoModal, setShowCommentInfoModal] = React.useState(false);
  const [dataForCommentInfo, setDataForCommentInfo] = React.useState(null);
  // const [activeKey] = React.useState(null);
  const [isDropDown, setIsDropDown] = React.useState(false);
  const [isUploadModal, setIsUploadModal] = React.useState(false);
  const [feedbackList, setFeedbackList]: any = React.useState([]);
  const [isRecordModal, setIsRecordModal] = React.useState('');

  const scrollerRef = React.useRef<HTMLDivElement>(null);
  const inputFileRef = React.useRef() as MutableRefObject<HTMLInputElement>;
  const localDataAvailable = React.useRef(false);
  const [copied, setCopied] = useState(false);
  const [showCinemaModal, setShowCinemaModal] = useState('');
  const [playerData, setPlayerData] = useState({
    createdTime: '',
    blobURL: '',
    videoURL: '',
    iframeClass: '',
    isMediaCreatedByLoginUser: false,
  });
  // const [stateChatId, setStateChatId] = useState('');
  const [prevChatId, setPrevChatId]: any = useState('');
  const [loading, setLoading] = useState(false);
  const [chatData, setChatData] = useState({
    messages: [],
    count: 0,
    chatId: '',
    prevChatId: '',
    lastMesaages: [],
  });

  const chatFeatureRef = useRef({
    messages: [],
    count: 0,
    chatId: '',
    prevChatId: '',
    lastMesaages: [],
  });
  const [selectedChatOption, setSelectedChatOption] = useState('message');
  
  const [userPushNotificationTokens, setUserPushNotificationTokens] = useState([]);
  const [isOpenMediaPermissionModal, setIsOpenMediaPermissionModal] = useState(false);
  // const [stateProject, setStateProject] = useState(match.params.id)

  /*eslint-disable */
  const isDataUrlString = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
  /*eslint-enable */
  const recordVoiceClipTime = (userQuota && userQuota.recordVoiceClipTime) || 10;

  useEffect(() => {
    if (projectID) {
      const firestore = firebase.firestore();
      firestore
        .collection('projects')
        .where(firebase.firestore.FieldPath.documentId(), '==', projectID)
        .get()
        .then((projectDetails: any) => {
          if (projectDetails && projectDetails.docs && projectDetails.docs.length) {
            const projectDetailData = projectDetails.docs[0].data();
            const userPushNotificationTokensArray =
              projectDetailData && projectDetailData.userPushNotificationTokens
                ? projectDetailData.userPushNotificationTokens
                : [];
            setUserPushNotificationTokens(userPushNotificationTokensArray);
          }
        });
    }
  }, [projectID]);
  // to clear all messages with Prop
  useEffect(() => {
    if (clearOldChat) {
      setFeedbackList([]);
      setClearOldChat(false);
    }
  }, [clearOldChat]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
  }, [copied]);

  React.useEffect(() => {
    const userDetails = localStorage.getItem('userDetails');
    if (userDetails && auth.isEmpty) {
      const userData = JSON.parse(userDetails);
      if (userID === undefined) {
        setUserID(userData.uid);
      }
    } else if (auth.uid) {
      if (userID === undefined) {
        setUserID(auth.uid);
      }
    }
  }, [
    // pages, circlePointerID
  ]);

  const eventHandler = (e: any) => {
    const eventData = e.data;

    switch (eventData.data) {
      case 'SCROLL_TO_CHAT':
        if (scrollerRef.current !== null) {
          // scrollerRef.current.scrollIntoView({
          //   behavior: 'smooth',
          //   block: 'nearest',
          //   inline: 'start',
          // });
        }

        break;
      default:
    }
  };
  useEffect(() => {
    if (chatId) {
      const firestore = firebase.firestore();
      firestore
        .collection('chat')
        .doc(chatId)
        .onSnapshot((chatDataProject: any) => {
          if (chatDataProject.data()) {
            const firebaseChatData = JSON.parse(JSON.stringify(chatDataProject.data()));
            // if (firebaseChatData.prevChatId && firebaseChatData.prevChatId !== ""
            //   && firebaseChatData.messages.length < 20) {
            //   firestore
            //     .collection('chat')
            //     .doc(firebaseChatData.prevChatId)
            //     .onSnapshot((prevChatDataProject: any) => {
            //       const chatBkp: any = JSON.parse(JSON.stringify(firebaseChatData.messages));
            //       const prevchatBkp: any = JSON.parse(JSON.stringify(prevChatDataProject.data()))
            //       chatBkp.unshift(...prevchatBkp.messages)
            //       const latestChatData = {
            //         chatId,
            //         ...prevchatBkp,
            //         messages: chatBkp,
            //         lastMesaages: chatBkp
            //         // firebaseChatData.messages
            //       }
            //       setChatData(JSON.parse(JSON.stringify(latestChatData)));
            //     });
            // } else {
            setChatData({
              // prevChatId: '',
              chatId,
              ...firebaseChatData,
              messages: JSON.parse(JSON.stringify(firebaseChatData.messages)),
            });
            chatFeatureRef.current = {
              // prevChatId: '',
              chatId,
              ...firebaseChatData,
              messages: JSON.parse(JSON.stringify(firebaseChatData.messages)),
            }
            // }
          }
        });
    }
  }, [chatId]);

  useEffect(() => {
    // if (chatData.chatId) {
    //   setStateChatId(chatData.chatId);
    // }
    //   scrollToClickedComment();
    window.addEventListener('message', eventHandler, false);

    const matchedIndex = feedbackList.findIndex((newFeedback: any) => {
      // let chatDataTempMessage = JSON.parse(JSON.stringify(chatData.messages))
      // if (chatData.lastMesaages <= chatData.messages) {
      //   chatDataTempMessage = JSON.parse(JSON.stringify(chatData.lastMesaages))
      // }
      return chatData.messages.find((feedback: any) => {
        return feedback.uuid === newFeedback.uuid;
      });
    });

    if (matchedIndex === -1) {
      // const chatBkp: any = chatData.messages;

      // if (chatData.prevChatId && chatData.prevChatId !== "" && chatData.messages.length < 150) {
      //   const firestore = firebase.firestore();
      //   firestore
      //     .collection('chat')
      //     .doc(chatData.prevChatId)
      //     .onSnapshot((chatDataProject: any) => {
      //       chatBkp.unshift(...chatDataProject.data().messages)
      //       setFeedbackList((oldMessages: any) => {
      //         oldMessages.push(...chatBkp)
      //         return JSON.parse(JSON.stringify(oldMessages))
      //       });
      //     });
      // } else {
      // setFeedbackList((oldMessages: any) => {
      //   oldMessages.push(...chatData.messages)
      //   return JSON.parse(JSON.stringify(oldMessages))
      // });
      // }

      setFeedbackList((oldMessages: any) => {
        oldMessages.push(...chatData.messages);
        return JSON.parse(JSON.stringify(oldMessages));
      });
    } else {
      const tempFeedbackList = JSON.parse(JSON.stringify(feedbackList));

      const newArray = JSON.parse(JSON.stringify(tempFeedbackList.splice(0, matchedIndex)));
      // const newnewArray = feedbackList.concat(newArray)
      const tempLatestMessages = newArray.concat(chatData.messages);

      // if (chatData.prevChatId && newnewArray.length < 150) {
      //   const firestore = firebase.firestore();
      //   firestore
      //     .collection('chat')
      //     .doc(chatData.prevChatId)
      //     .onSnapshot((chatDataProject: any) => {
      //       newnewArray.unshift(...chatDataProject.data().messages)
      //     });
      // }

      setFeedbackList(tempLatestMessages);
    }

    // window.parent.postMessage('LIVE_CHAT_NEW_MESSAGE', '*');
  }, [chatData.messages]);

  useEffect(() => {
    if (chatData.prevChatId && !localDataAvailable.current) {
      setPrevChatId(chatData.prevChatId);
    }
  }, [chatData.prevChatId]);

  useEffect(() => {
    if (scrollerRef.current !== null && allowScrollToBootom) {
      // @ts-ignore
      // return scrollerRef.current.scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'nearest',
      //   inline: 'start',
      // });
    }
    return setScrollIntoView(false);
  }, [scrollIntoView, feedbackList]);

  const sendFeedbackFunc = async (textValue: any) => {
    trackAnalytics(`${trackAnalyticsPrefix}Chat window`, 'Send message clicked');
    setAllowScrollToBootom(true);
    // check authentication
    if (!isInterviewCanvas && auth.isEmpty) {
      // open modal to get public user details
      showDetailModal();
    } else if (textValue.trim() !== '') {
      await sendFeedback(textValue, '', false, pageID, false, '', '');
    }
    return setScrollIntoView(true);
  };

  // const sendMessage = async (firebaseLoginUserToken: string) => {
  //   clearShowingMessageid && clearShowingMessageid();
  //   setAllowScrollToBootom(true);
  //   await sendFeedback(value, '', false, pageID, false, '', '', '', firebaseLoginUserToken);
  // };

  const isDataURL = (s: string) => {
    return !!s.match(isDataUrlString);
  };
  const handleResolve = () => {
    if (isWriteAccess) {
      if (auth.isEmpty) {
        // open modal to get public user details
        showDetailModal();
      } else {
        trackAnalytics(
          `${trackAnalyticsPrefix}Chat window`,
          resolved ? 'Resolve feedback' : 'Unresolve feedback',
        );
        const pageClone = pages ? { ...pages[pageID], id: pageID } : null;
        resolvePageFeedback(circlePointerID, pageClone, iframe);
        onClose && onClose();
      }
    }
  };

  const sendFeedback = async (
    message: any,
    originalImg: any,
    checkImage: boolean,
    pageIDValue: string,
    checkFile = false,
    fileName = '',
    audioUrl: string,
    videoFirebaseUrl?: string,
    firebasePublicUserAuthToken?: string,
    blobURL?: string,
  ) => {
    let firebaseAuthUserToken = firebaseAuthToken ? firebaseAuthToken : firebasePublicUserAuthToken;
    if (!isInterviewCanvas) {
      const fiebaseInstance: any = firebase;
      firebaseAuthUserToken = firebaseAuthUserToken
        ? firebaseAuthUserToken
        : await fiebaseInstance.auth().currentUser.getIdToken(true);
      !firebaseAuthUserToken && updateFirebaseAuthToken(firebaseAuthUserToken || '');
      clearShowingMessageid && clearShowingMessageid();
    }
    setAllowScrollToBootom(true);
    const userDetails: any = localStorage.getItem('userDetails');
    let attachment = '';
    let attachedFileName = '';
    let text = message;
    let thumbnail = '';
    let audioUrlData = '';
    let videoUrl = '';
    if (checkImage) {
      thumbnail = message;
      text = '';
    }
    if (videoFirebaseUrl) {
      videoUrl = videoFirebaseUrl;
    }
    if (audioUrl) {
      audioUrlData = audioUrl;
      text = '';
    }
    if (checkFile) {
      attachment = message;
      attachedFileName = fileName;
    }
    const data = {
      thumbnail,
      originalImage: originalImg,
      feedback: text,
      email: '',
      sender: '',
      time: moment()
        .utc()
        .valueOf(),
      uid: '',
      attachment,
      attachedFileName,
      uuid: '',
      audioUrl: audioUrlData,
      userAgent: window.navigator.userAgent,
      videoUrl,
      blobURL: blobURL ? blobURL : '',
      photoURL,
      pageIDValue: pageIDValue || '',
    };

    let uniqueUuid: any;
    uniqueUuid = uuid();
    if (inProgress.length > 0) {
      const firstElement: any = inProgress.shift();
      uniqueUuid = firstElement.uuid;
    }
    if (auth.isEmpty && userDetails) {
      // user not Login but userdata is saved
      const userData = JSON.parse(userDetails);
      data.sender = userData.name;
      data.uid = userData.uid;
      data.email = userData.email;
    } else if (auth.uid) {
      setUserID(auth.uid);
      data.sender = fullName;
      data.uid = auth.uid;
    }
    data.uuid = uniqueUuid;

    const firestore = firebase.firestore();

    // firestore
    //   .collection('projects')
    //   .where(firebase.firestore.FieldPath.documentId(), '==', projectID)
    //   .get()
    //   .then((projectDetails: any) => {
    //     if (projectDetails && projectDetails.docs && projectDetails.docs.length) {
    //       const projectDetailData = projectDetails.docs[0].data();
    //       const userPushNotificationTokens =
    //         projectDetailData && projectDetailData.userPushNotificationTokens
    //           ? projectDetailData.userPushNotificationTokens
    //           : [];
    let receiversToken = userPushNotificationTokens.map((userToken: any) => userToken.token);

    const firebaseToken = window.localStorage.getItem('firebaseToken');
    if (firebaseToken) {
      receiversToken = receiversToken.filter((item: string) => item !== firebaseToken);
    }

    // const firebaseNotificationAuthKey = process.env.REACT_APP_FIREBASE_NOTIFICATION_AUTH_KEY;
    // const firebaseNotificationURL: any = process.env.REACT_APP_FIREBASE_NOTIFICATION_URL;
    let url = window.location.href;
    const notificationId = uuid();
    if (url.includes('public-chat')) {
      url = url.replace('public-chat', `project/${projectID}`);
    }
    // const projectURL = `${url}${
    //   url.includes('?') ? '&' : '?'
    //   }circleId=${circlePointerID}&commentId=${pageIDValue}&notificationId=${notificationId}`;
    const projectURL = `${url}${url.includes('?') ? '&' : '?'}notificationId=${notificationId}`;

    let feedbackMessage: string = '';
    if (videoFirebaseUrl) {
      feedbackMessage =
        isRecordModal === 'video'
          ? `${data.sender} sent video comment!`
          : `${data.sender} sent screen recording file!`;
    } else if (audioUrl) {
      feedbackMessage = `${data.sender} sent a voice clip!`;
    } else if (checkFile || originalImg) {
      feedbackMessage = `${data.sender} sent a file!`;
    } else {
      feedbackMessage = `${data.sender} sent a ${message} message`;
    }
    const initials = (data && data.sender && data.sender[0]) || '';
    feedbackMessage &&
      firebaseAuthUserToken &&
      sendMessageToSlack(
        firebaseAuthUserToken,
        `${feedbackMessage}`,
        projectID,
        projectURL,
        initials,
        notificationId,
      );

    // if (receiversToken && receiversToken.length) {
    //   fetch(firebaseNotificationURL, {
    //     method: 'POST',
    //     headers: new Headers({
    //       'content-Type': 'application/json',
    //       Authorization: `key=${firebaseNotificationAuthKey}`,
    //     }),

    //     body: JSON.stringify({
    //       data: {
    //         message: feedbackMessage,
    //         title: 'Zooc',
    //         body: 'Click here to see all the feedback points.',
    //         data: {
    //           url: window.location.href,
    //         },
    //         click_action: window.location.href,
    //         page_id: pageIDValue,
    //         circle_id: circlePointerID,
    //         icon: `${window.location.origin}/icon.png`,
    //         page_path: getQueryStringValue('page'),
    //         url: window.location.href,
    //       },
    //       registration_ids: receiversToken,
    //     }),
    //   });
    // }
    //   }
    // });

    const chatDataLatest: any = chatFeatureRef.current.messages;
    if (chatDataLatest.length >= 500) {
      // const tempData = [];
      // for (let i = 1; i <= 5; i++) {
      //   tempData.push(
      //     {
      //       thumbnail,
      //       originalImage: originalImg,
      //       feedback: i.toString(),
      //       email: '',
      //       sender: '',
      //       time: moment()
      //         .utc()
      //         .valueOf(),
      //       uid: '',
      //       attachment,
      //       attachedFileName,
      //       uuid: '',
      //       audioUrl: audioUrlData,
      //       userAgent: window.navigator.userAgent,
      //       videoUrl,
      //       blobURL: blobURL ? blobURL : '',
      //       photoURL: auth.photoURL
      //     }
      //   );
      // }

      const nextChatId = await firestore
        .collection('chat')
        .add({
          messages: [data],
          count: chatFeatureRef.current.count + 1,
          prevChatId: chatFeatureRef.current.chatId,
        })
        .then((obj): any => {
          return obj.id;
        })
        .catch(() => {
          return false;
        });
      const threadMessage = {
        message: text ? text : 'Untitled thread',
        createdAt: data.time,
        messageId: nextChatId,
        chatMessageCount: chatFeatureRef.current.count + 1,
      };
      if (chatFeatureRef.current.count === 0) {
        addMessageInThread && addMessageInThread(threadMessage, nextChatId);
      } else {
        addMessageInThread && addMessageInThread(threadMessage, nextChatId);
      }
      localDataAvailable.current = true;
      firestore
        .collection('chat')
        .doc(chatFeatureRef.current.chatId)
        .set(
          {
            nextChatId,
          },
          { merge: true },
        );

      // this updates new chat id to project
      if (!addMessageInThread) {
        const updatedTimeStamp = new Date().getTime();
        firestore
          .collection('masterProjects')
          .doc(projectID)
          .set(
            {
              chatId: nextChatId,
              updatedTimeStamp,
            },
            { merge: true },
          );
      }

      // firestore
      //   .collection('chat')
      //   .doc(chatFeatureRef.current.chatId)
      //   .set({ prevChatId: chatId }, { merge: true })
    } else {
      // for (let i = 1; i <= 300; i++) {
      //   chatDataLatest.push(
      //     {
      //       thumbnail,
      //       originalImage: originalImg,
      //       feedback: i.toString(),
      //       email: '',
      //       sender: '',
      //       time: moment()
      //         .utc()
      //         .valueOf(),
      //       uid: '',
      //       attachment,
      //       attachedFileName,
      //       uuid: '',
      //       audioUrl: audioUrlData,
      //       userAgent: window.navigator.userAgent,
      //       videoUrl,
      //       blobURL: blobURL ? blobURL : '',
      //       photoURL: auth.photoURL
      //     }
      //   );
      // }
      chatDataLatest.push(data);
      firestore
        .collection('chat')
        .doc(chatFeatureRef.current.chatId)
        .set(
          {
            messages: chatDataLatest,
            count: chatFeatureRef.current.count + 1,
          },
          { merge: true },
        )
        .then((obj: any) => {
          const threadMessage = {
            message: text ? text : 'Untitled thread',
            createdAt: data.time,
            messageId: chatFeatureRef.current.chatId,
            chatMessageCount: chatFeatureRef.current.count + 1,
          };
          if (chatFeatureRef.current.count === 0) {
            addMessageInThread && addMessageInThread(threadMessage, chatFeatureRef.current.chatId);
          } else {
            addMessageInThread && addMessageInThread(threadMessage, chatFeatureRef.current.chatId);
          }
          return obj;
        })
        .catch(() => {
          return false;
        });
    }
    if (checkImage) {
      statsAction('IMAGE_COMMENT_SENT', firestore);
    } else {
      statsAction('TEXT_COMMENT_SENT', firestore);
    }
    setUpdateInput((inputUpdate: any) => !inputUpdate);
    setSelectedChatOption("message");
  };

  const setImagePreview = (image: string) => {
    setImage(image);
    setPreview(true);
  };

  const getImageBlob = (data: any) => {
    const items = data && (data.clipboardData || data.originalEvent.clipboardData).items;
    let blob = null;
    for (const item of items) {
      if (item.type.indexOf('image') === 0) {
        blob = item.getAsFile();
      }
    }
    if (blob !== null) {
      // setSendingImage(true);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        if (isDataURL(e.target.result)) {
          updateFeedbackFileSelect(1);
          const originalImg: any = await sendImageToStore(e.target.result);
          createThumbNail(e.target.result, originalImg).then((thumbnail) => {
            sendFeedback(thumbnail, originalImg, true, pageID, false, '', '');
          });
        } else {
          setValue(e.target.result);
          setUpdateInput((inputUpdate: any) => !inputUpdate);
        }
      };
      reader.readAsDataURL(blob);
    }
  };

  const setRefForImage = () => {
    if (!!scrollerRef.current && !currentShowingMessageid) {
      // scrollerRef.current.scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'nearest',
      //   inline: 'start',
      // });
    }
  };
  const sendImageToStore = (dataUrl: string) => {
    const fileName = 'file' + new Date().getTime();
    const storageRef = firebase
      .storage()
      .ref()
      .child(fileName);
    return storageRef.putString(dataUrl, 'data_url').then((imageData: any) => {
      return imageData.ref.getDownloadURL().then((url: string) => {
        return url;
      });
    });
  };

  const sendVideoToStore = (blob: any) => {
    // setSendingImage(true);

    const fileName = 'file' + new Date().getTime();
    const storageRef = firebase
      .storage()
      .ref()
      .child(fileName);
    return storageRef.put(blob).then((videoData: any) => {
      return videoData.ref.getDownloadURL().then((url: string) => {
        return url;
      });
    });
  };

  const createThumbNail = (data: string, originalImageUrl: string) =>
    new Promise((resolve) => {
      const img = new Image();
      img.src = data;
      img.onload = async () => {
        if (img.height > 200 || img.width > 200) {
          const elem = document.createElement('canvas');
          let maxWidth = 200;
          let maxHeight = 200;
          if (img.height > img.width) {
            maxHeight = 200;
            maxWidth = (img.width / img.height) * maxHeight;

            elem.height = maxHeight;
            elem.width = maxWidth;
          } else {
            maxWidth = 200;
            maxHeight = (img.height / img.width) * maxWidth;

            elem.height = maxHeight;
            elem.width = maxWidth;
          }
          const ctx: any = elem.getContext('2d');
          ctx.drawImage(img, 0, 0, maxWidth, maxHeight);
          const dataUrl = elem.toDataURL();
          const thumbnail: any = await sendImageToStore(dataUrl);
          resolve(thumbnail);
        } else {
          resolve(originalImageUrl);
        }
      };
    });

  const showDetailModal = () => {
    // open modal to get public user details
    Modal.confirm({
      content: '',
      okText: 'Yes',
      cancelText: 'No',
      title: 'Please Sign Up to continue!',
      onOk: () => {
        history.push('/signup');
      },
    });
  };

  const callback = (response: any) => {
    if (!response.cancel) {
      const fileName = response ? response.fileName : null;
      if (!fileName) {
        const thumbnailImg = response.thumbnail;
        const originalImage = response.originalImg;
        sendFeedback(thumbnailImg, originalImage, true, pageID, false, '', '');
      } else {
        const originalFile = response.originalFile;
        sendFeedback(originalFile, '', false, pageID, true, fileName, '');
      }
    }
  };
  const scrollToClickedComment = () => {
    if (currentShowingMessageid && chatContainerRef.current) {
      const element = itemRefs[currentShowingMessageid];
      const topOffset = 200;
      const elementPosition =
        element && element.getBoundingClientRect() && element.getBoundingClientRect().top;
      const offsetPosition = elementPosition - topOffset;
      chatContainerRef.current.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    } else {
      if (scrollerRef.current !== null && chatContainerRef.current) {
        // return chatContainerRef.current.scrollTo({
        //   top: chatContainerRef.current.scrollHeight,
        //   behavior: 'smooth',
        // });
      }
    }
  };

  // const onStop = (recordedBlob: any) => {
  //   setAudioRecorded(recordedBlob);
  // };

  // const preload = (status: boolean) => {
  //   setSendingImage(status);
  // };

  const fileHandler = async (fileObj: any) => {
    await UploadFileLogic(fileObj, callback, () => null, profile, auth, organization);
  };

  const fileChangeHandler = (e: any) => {
    const fileObj = e.target.files;
    updateFeedbackFileSelect(fileObj.length);
    fileHandler(fileObj);
  };

  const openCommentModal = (userAgent: any) => {
    setShowCommentInfoModal(true);
    setDataForCommentInfo(userAgent);
  };

  const triggerInputFile = () => inputFileRef.current.click();

  // const handleMouseEnter = (key: any) => {
  //   // setActiveKey(key);
  // };

  // const handleMouseLeave = (key: any) => {
  //   // setActiveKey(key);
  // };

  const generateFeed = (type = '') => {
    const userDetails = localStorage.getItem('userDetails');
    const data = {
      attachedFileName: '',
      attachment: '',
      feedback: 'Sending File...',
      originalImage: 'loading',
      sender: 'loading',
      thumbnail: '/images/small-loader.gif',
      time: moment()
        .utc()
        .valueOf(),
      uid: userID,
      uuid: uuid(),
      type,
    };
    if (userDetails && auth.isEmpty) {
      data.sender = fullName;
    }
    return data;
  };

  const fileUploadProgress = async (count: number, allFeedbackList: IFeedback[], type?: string) => {
    const uploading: any[] = [];
    Array.from(
      {
        length: count,
      },
      () => {
        uploading.push(generateFeed(type));
      },
    );
    inProgress = uploading;
    const mergedFeedbacks = [...allFeedbackList, ...uploading];
    setFeedbackList(mergedFeedbacks);
  };

  // const updateFeedback = async (count: number) => {
  //   await fileUploadProgress(count, feedbackList);
  //   scrollToClickedComment();
  // };

  const updateFeedbackFileSelect = async (count: number, type?: string) => {
    await fileUploadProgress(count, feedbackList, type);
    scrollToClickedComment();
  };

  const copyUrl = () => {
    trackAnalytics(`${trackAnalyticsPrefix}Chat window`, `copied URL`);
    const textArea = document.createElement('textarea');
    textArea.value = urlRefs.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
    setCopied(true);
  };

  const deleteFromFirebaseStorage = (firebaseStorageURL: string) => {
    firebase
      .storage()
      .refFromURL(firebaseStorageURL)
      .delete();
  };

  const deleteMedia = (selectedMessage: {
    videoUrl?: string;
    audioUrl?: string;
    thumbnail?: string;
    originalImage?: string;
  }) => {
    const { videoUrl = '', audioUrl = '', thumbnail = '', originalImage = '' } = selectedMessage;
    if (videoUrl) {
      videoUrl.includes('https://firebasestorage.googleapis.com') &&
        deleteFromFirebaseStorage(videoUrl);
      videoUrl.includes('embed.api.video') && deleteFromVideoStorage(firebaseAuthToken, videoUrl);
    } else if (audioUrl) {
      deleteFromFirebaseStorage(audioUrl);
    } else if (thumbnail) {
      deleteFromFirebaseStorage(thumbnail);
      deleteFromFirebaseStorage(originalImage);
    }
  };

  const deleteChatMessage = (key: number) => {
    Modal.confirm({
      content: '',
      title: 'Do you want to delete this message?',
      onOk: async () => {
        trackAnalytics(`${trackAnalyticsPrefix}Chat window`, 'Delete message clicked');
        const firestore = firebase.firestore();
        let authorId = localStorage.getItem('userDetails');
        authorId = authorId && JSON.parse(authorId).uid;
        const allMessages = feedbackList;
        const allFeedbacks = JSON.parse(JSON.stringify(allMessages));
        allFeedbacks[key].messageDeleted = true;
        deleteMedia(allFeedbacks[key]);
        firestore
          .collection('chat')

          .doc(chatData.chatId)
          .set(
            {
              messages: allFeedbacks,
            },
            { merge: true },
          );
      },
    });
  };

  const onAudioSubmit = async (firebaseUrl: any) => {
    await transcript(firebaseAuthToken, {
      audioURL: firebaseUrl, 
      chatId: chatFeatureRef.current.chatId
    })
    // setSendingImage(true);
    trackAnalytics(`${trackAnalyticsPrefix}Chat window`, 'Send recording clicked', {
      projectId: projectID,
    });
    // setIsRecordingModal(false);
    // updateFeedbackFileSelect(1);
    await sendFeedback('', '', false, pageID, false, '', firebaseUrl);
    // setSendingImage(false);
  };

  const deleteFeedback = async () => {
    trackAnalytics(`${trackAnalyticsPrefix}Chat window`, 'Deleted a message', {
      projectId: projectID,
    });
    const allFeedbacks = (pages[pageID] && pages[pageID].feedback) || [];
    let circleIndexToBeDeleted = -1;
    allFeedbacks.map((data: any, index: number) => {
      if (data.circle_pointer.id === circlePointerID) {
        circleIndexToBeDeleted = index;
      }
    });
    const allFeedbackTemp = JSON.parse(JSON.stringify(allFeedbacks));
    allFeedbackTemp.splice(circleIndexToBeDeleted, 1);
    const firestore = firebase.firestore();
    await firestore
      .collection('pages')
      .doc(pageID)
      .set(
        {
          feedback: allFeedbackTemp,
          annotations: pages[pageID].annotations,
        },
        { merge: true },
      );
    sendMessageToIframe(iframe, 'DELETE_FEEDBACK', { key: circlePointerID });
    trackAnalytics(`${trackAnalyticsPrefix}Chat window`, 'Deleted a feedback', {
      projectId: projectID,
    });
    onClose && onClose();
  };

  const locationUrl = window.location.href;
  const feedbackURL = `${locationUrl}${locationUrl.includes('?') ? '&' : '?'
    }circleId=${circlePointerID}`;

  const urlify = (text: string) => {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
      'i',
    );
    const textArray = text.split(' ').map((word: string) => {
      if (!!pattern.test(word)) {
        return `<a text-decoration: underline"
                  href="${word.includes('http') ? word : '//' + word}" target="_blank">${word}</a>`;
      }
      return `<span style="padding: 0px">${word}</span>`;
    });
    return textArray.join(' ');
  };

  const screenIconClick = () => {
    if (isRecordModal) {
      setIsRecordModal('')
      // @ts-ignore
      if (window.stream) {
        // @ts-ignore
        window && window.stream && window.stream.getTracks().forEach(function (track: any) {
          track.stop();
        });
        // @ts-ignore
        window.stream.getAudioTracks().forEach(function (track) {
          track.stop();
        });
        // @ts-ignore
        window.stream.getVideoTracks().forEach(function (track) {
          track.stop();
        });
        // @ts-ignore
        window.stream = null;
      }
    } else {
      if (auth.isEmpty) {
        // open modal to get public user details
        showDetailModal();
      } else {
        if (isWriteAccess && userQuota.storageLimit > 0) {
          setIsRecordModal('screen');
          trackAnalytics(`${trackAnalyticsPrefix}Chat window`, 'Send screen recording clicked', {
            projectId: projectID,
          });
        }
      }
    }
  };

  const videoIconClick = () => {
    if (isRecordModal) {
      setIsRecordModal('')
      // @ts-ignore
      window && window.stream && window.stream.getTracks().forEach(function (track: any) {
        track.stop();
      });
    } else {
      if (auth.isEmpty) {
        // open modal to get public user details
        showDetailModal();
      } else {
        if (isWriteAccess && userQuota.storageLimit > 0) {
          trackAnalytics(`${trackAnalyticsPrefix}Chat window`, 'Send video clicked', {
            projectId: projectID,
          });
          setIsRecordModal('video');
        }
      }
    }
  };

  // const audioIconClick = () => {
  //   if (isRecordingModal) {
  //     setIsRecordingModal(false)
  //     // @ts-ignore
  //     window && window.stream && window.stream.getTracks().forEach(function (track: any) {
  //       track.stop();
  //     });
  //   } else {
  //     if (auth.isEmpty) {
  //       // open modal to get public user details
  //       showDetailModal();
  //     } else {
  //       if (isWriteAccess && userQuota.storageLimit > 0) {
  //         setIsRecordingModal(true);
  //         trackAnalytics(`${trackAnalyticsPrefix}Chat window`, 'Send audio clicked', {
  //           projectId: projectID,
  //         });
  //       }
  //     }
  //   }
  // };

  const uploadIconClick = () => {
    if (auth.isEmpty) {
      // open modal to get public user details
      showDetailModal();
    } else {
      if (isWriteAccess && userQuota.storageLimit > 0) {
        triggerInputFile();
        trackAnalytics(`${trackAnalyticsPrefix}Chat window`, 'Upload file clicked', {
          projectId: projectID,
        });
      }
    }
  };

  const loadMoreMessages = () => {
    setLoading(true);
    if (prevChatId) {
      const firestore = firebase.firestore();
      firestore
        .collection('chat')
        .doc(prevChatId)
        .get()
        .then((chatDataProject: any) => {
          const data = chatDataProject.data();
          // let tempFeedbackList = feedbackList;
          const tempFeedbackList = JSON.parse(JSON.stringify(feedbackList));
          tempFeedbackList.unshift(...data.messages);
          setFeedbackList(tempFeedbackList);
          setPrevChatId(data.prevChatId);
          setLoading(false);
        });
    }
  };

  const renderChatMessages = () => {
    const messages = feedbackList;
    return (
      <InfiniteScroll
        initialLoad
        pageStart={0}
        loadMore={() => {
          loadMoreMessages();
        }}
        isReverse={true}
        hasMore={prevChatId && !loading ? true : false}
        loader={<div style={{ height: 50 }} className='gif-loader'></div>}
        useWindow={false}
        threshold={1}
        getScrollParent={chatContainerRef.current}
      >
        <div>
          {messages.length > 0 &&
            messages.map((feedback: any, key: number) => {
              const { sender, time, uid } = feedback;

              const date = moment(time).fromNow() || '';
              const dateSeprator = moment(time).format('DD-MM-YYYY');
              const dateSingleMsg = moment(time).format('HH:mm');

              const nextMessageTime = messages[key + 1] && messages[key + 1].time;
              const oldUserId = messages[key - 1] && messages[key - 1].uid;
              const dateSepratorNext = moment(nextMessageTime).format('DD-MM-YYYY');
              let dateFormat = 'MMM Do [at] h:mm:ss A z';
              if (moment(time).years() !== new Date().getFullYear()) {
                dateFormat = 'MMM Do, YYYY [at] h:mm:ss A z';
              }
              const dateToolTip = moment(time).format(dateFormat) || '';
              // let isShowBlobProcessedMessage = false;
              // if (uid === userID && blobURL) {
              //   const currentUTCTime = moment().utc();
              //   const minutes = moment(currentUTCTime).diff(moment(time), 'minutes');
              //   if (minutes < 1) {
              //     isShowBlobProcessedMessage = true;
              //   }
              // }
              const senderNameInitialsArr = sender ? sender.split(' ') : [];
              const isfullWidthMessage =
                feedback.type === 'video' ||
                feedback.thumbnail ||
                feedback.audioUrl ||
                feedback.videoUrl;
              const senderNameInitials =
                ((senderNameInitialsArr[0] && senderNameInitialsArr[0][0]) || '') +
                ((senderNameInitialsArr[1] && senderNameInitialsArr[1][0]) || '');

              return (
                <Row
                  key={key}
                  ref={(el) => {
                    itemRefs[feedback.uuid] = el;
                  }}
                  // onMouseEnter={() => handleMouseEnter(key)}
                  // onMouseLeave={() => handleMouseLeave(null)}
                  className={`chat-window-message-container-header ${nextMessageTime && dateSepratorNext !== dateSeprator
                      ? 'chat-window-seprator'
                      : ''
                    }
                                    ${oldUserId === uid
                      ? 'chat-window-message-container-no-header'
                      : ''
                    }`}
                >
                  <>
                    {oldUserId !== uid && (
                      <Row>
                        <Col span={4}>
                          {feedback.photoURL ? (
                            <Avatar size='small' src={feedback.photoURL} icon='user' />
                          ) : (
                            <Avatar size='small'>{senderNameInitials.toUpperCase()}</Avatar>
                          )}
                        </Col>
                        <Col style={{ textAlign: 'left', display: 'flex' }} span={20}>
                          <span className='chat-window-name'>{sender}</span>
                          <span className='chat-window-time'>
                            <Tooltip placement='bottomRight' title={dateToolTip}>
                              {date}
                            </Tooltip>
                          </span>
                        </Col>
                      </Row>
                    )}
                    {/* put 2 instead of 4 if need more width */}
                    <Col className='chat-window-side-time' span={isfullWidthMessage ? 4 : 4}>
                      <span
                        className='chat-window-time d-none show-hover-element'
                        style={{ lineHeight: '14px', zIndex: 1, marginTop: '4px' }}
                      >
                        {// activeKey === key &&
                          // no hover for loading case
                          !(feedback.originalImage === 'loading' && feedback.type !== 'video') && (
                            // && !isfullWidthMessage
                            <Tooltip placement='bottomLeft' title={dateToolTip}>
                              {dateSingleMsg}
                            </Tooltip>
                          )}
                      </span>
                    </Col>
                    {/* put 22 instead of 20 if need more width */}

                    <Col span={isfullWidthMessage ? 20 : 20}>
                      {feedback.messageDeleted ? (
                        <div
                          className='chat-window-message chat-window-hovered-background'
                          style={{
                            fontStyle: 'italic',
                            color: '#B0BEC5',
                            // backgroundColor: activeKey === key ? '#FAFAFA' : '',
                            padding: '2px',
                          }}
                        >
                          <Icon type='stop' />
                          {feedback.uid === userID
                            ? ' You deleted this message'
                            : ' This message was deleted'}
                        </div>
                      ) : feedback.originalImage === 'loading' && feedback.type !== 'video' ? (
                        <>
                          <div
                            style={{
                              padding: '2px',
                              height: '88px',
                              width: '180px',
                            }}
                            className='chat-window-message d-flex'
                          >
                            <img
                              style={{ height: '43px', width: '80px' }}
                              src='/images/small-loader.gif'
                              alt='Loading...'
                            />
                          </div>
                        </>
                      ) : feedback.thumbnail && feedback.type !== 'video' ? (
                        <div className='chat-window-image-container'>
                          <img
                            alt='preview'
                            className='chat-window-feedback-image c-p'
                            src={feedback.thumbnail}
                            onClick={() => setImagePreview(feedback.originalImage)}
                            onLoad={setRefForImage}
                            ref={imgRef}
                          />
                        </div>
                      ) : feedback.audioUrl ? (
                        <>
                        <div
                          className={`chat-window-canvas-audio chat-window-message chat-window-hovered-background`}
                        >
                          <AudioPlayer id={key.toString()} url={feedback.audioUrl} />
                        </div>
                        { feedback.aiAudioTextSummary ? <div>
                          <span style={{fontSize: '14px', display: 'flex'}}>AI Summary: </span>
                          <span style={{fontSize: '12px', color: '#636262', fontStyle: 'italic', display: 'flex', textAlign: 'left'}}>{feedback.aiAudioTextSummary}</span>
                        </div> : null }
                        </>
                      ) : feedback.attachment ? (
                        <div style={{ maxWidth: '100%' }}>
                          {' '}
                          <Tooltip placement='top' title='Click to download'>
                            <a href={feedback.attachment} target='_blank'>
                              {/* <Button className='file-uploaded-text' type='primary'>
                                                                {feedback.attachedFileName}
                                                            </Button> */}
                              <div className='file-uploaded-text file-uploaded-wrapper'>
                                {feedback.attachedFileName}
                                <svg
                                  style={{ marginLeft: '10px' }}
                                  width='10'
                                  height='11'
                                  viewBox='0 0 10 11'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    fill-rule='evenodd'
                                    clip-rule='evenodd'
                                    d='M4.52975 8.54288V0.75H5.54398V8.54288L8.73522 5.39644L9.45239 6.10355L5.03687 10.4571L0.621338 6.10355L1.33851 5.39644L4.52975 8.54288Z'
                                    fill='#9B9B9B'
                                  />
                                </svg>
                              </div>
                            </a>
                          </Tooltip>
                        </div>
                      ) : feedback.videoUrl ? (
                        <div
                          className={`chat-window-canvas-audio chat-window-message chat-window-hovered-background`}
                        >
                          <ChatVideoPlayer
                            isMediaCreatedByLoginUser={feedback.uid === userID}
                            createdTime={feedback.time}
                            blobURL={feedback.blobURL}
                            videos={[feedback.videoUrl]}
                            parentComponent='feedbackModal'
                            setShowCinemaModal={setShowCinemaModal}
                            setPlayerData={setPlayerData}
                            containerClass='chat-window-audio-player-content'
                            isChatWindow
                          />
                        </div>
                      ) : !feedback.videoUrl && feedback.type === 'video' ? (
                        <div className={`video-player chat-window-hovered-background`}>
                          <div
                            style={{
                              padding: '2px',
                              height: '88px',
                              width: '180px',
                            }}
                            className='text-center chat-window-message'
                          >
                            {feedback.videoStatus === 'DELETED'
                              ? 'Something went wrong with this video, please try again.'
                              : 'This video is being processed, please wait a few seconds.'}
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            padding: '2px',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: urlify(feedback.feedback),
                          }}
                          className='feedback-text chat-window-message chat-window-hovered-background'
                        />
                      )}
                      <span className='chat-window-hover-menu d-none show-hover-element'>
                        {// activeKey === key &&
                          feedback.uid === userID &&
                          !feedback.messageDeleted &&
                          // no hover for loading case
                          !(feedback.originalImage === 'loading' && feedback.type !== 'video') && (
                            <React.Fragment>
                              <Tooltip placement='left' title={'Delete'}>
                                <span
                                  style={{ marginRight: 10, backgroundColor: '#FFF' }}
                                  className={
                                    !isWriteAccess
                                      ? 'info-circle-icon disabled'
                                      : 'info-circle-icon c-p'
                                  }
                                  onClick={() => {
                                    isWriteAccess && deleteChatMessage(key);
                                  }}
                                >
                                 <DeleteIcon />
                                </span>
                              </Tooltip>
                            </React.Fragment>
                          )}
                          { !(feedback.originalImage === 'loading' && feedback.type !== 'video') && feedback.audioUrl && (
                            <React.Fragment>
                              <Tooltip placement='left' title={'Transcript'}>
                                <span
                                  className='info-circle-icon c-p'
                                  style={{ backgroundColor: '#FFF' }}
                                  onClick={() => openCommentModal(feedback.aiAudioTextTranscript || "Transcript is in-progress...")}
                                >
                                 <NavigatorIcon />
                                </span>
                              </Tooltip>
                            </React.Fragment>
                          )}
                      </span>
                    </Col>
                    {nextMessageTime && dateSepratorNext !== dateSeprator && (
                      <div className={'chat-window-message-seprator'}>{dateSeprator}</div>
                    )}
                  </>
                </Row>
              );
            })}
        </div>
      </InfiniteScroll>
    );
  };

  function getStream(constraints: any) {
    if (!constraints) {
      constraints = { audio: true, video: false };
    }
    return navigator.mediaDevices.getUserMedia(constraints);
  }

  // if (!userQuota) {
  //   return <div></div>;
  // }

  return (
    <ColorContext.Consumer>
      {({ theme }) => (
        <React.Fragment>
          {/* {  Need to remove this code } */}
          {!isPublicChatWindow && !auth.isEmpty && !isChatWindow && false && (
            <Dropdown
              overlay={() => (
                <Menu>
                  <Menu.Item disabled={!isWriteAccess} onClick={handleResolve}>
                    {!resolved ? 'Resolve Feedback' : 'Unresolve Feedback'}
                  </Menu.Item>
                  <Menu.Item
                    disabled={!isWriteAccess}
                    onClick={() => {
                      if (auth.isEmpty) {
                        // open modal to get public user details
                        showDetailModal();
                      } else {
                        if (isWriteAccess) {
                          Modal.confirm({
                            content: '',
                            okText: 'Yes',
                            cancelText: 'No',
                            title: 'Do you want to delete this feedback?',
                            onOk: deleteFeedback,
                          });
                        }
                      }
                    }}
                  >
                    Delete feedback
                  </Menu.Item>
                  <Menu.Item
                    disabled={!isWriteAccess}
                    onClick={() => {
                      isWriteAccess && copyUrl();
                    }}
                  >
                    <span
                      className='comment-url'
                      ref={(el) => {
                        urlRefs = el;
                      }}
                    >
                      {feedbackURL}
                    </span>
                    {`${copied ? 'Copied' : 'Copy'}`} Feedback URL
                  </Menu.Item>
                </Menu>
              )}
              placement={direction === 'left' ? 'bottomRight' : 'bottomLeft'}
            >
              <Button
                size='small'
                type='primary'
                icon='down'
                style={{ backgroundColor: '#444F60', borderColor: '#444F60' }}
                onClick={() => setIsDropDown(true)}
                shape='circle'
                className={'chat-header-mobile-content-dropdown'}
              />
            </Dropdown>
          )}

          {isDropDown && <div></div>}
          {/* <DragAndDropZone
              pageId={pageID}
              preload={preload}
              callback={callback}
              circlePointerID={circlePointerID}
              mockUpload={updateFeedback}
            > */}
          <div
            style={{
              flex: 1,
              overflow: 'scroll',
              flexDirection: 'column-reverse',
              display: 'flex',
            }}
            ref={chatContainerRef}
          >
            {renderChatMessages()}

            <div ref={scrollerRef} />
          </div>
          {selectedChatOption === 'audio' ?
            <RecordAudioNew
              maxRecordTime={recordVoiceClipTime}
              projectID={projectID}
              setLoading={() => updateFeedbackFileSelect(1)}
              onClose={() => console.log("close modal")}
              showPercentage
              onSubmit={(audioUrl: any) => {
                onAudioSubmit(audioUrl)
              }}
              background={theme.backgrounds.color2}
              profile={profile}
              auth={auth}
              organization={organization}
              setSelectedChatOption={setSelectedChatOption}
            />
            :
            (userQuota || isInterviewCanvas) && (
            <div style={{ background: theme.backgrounds.color1, borderRadius: 10 }}>
              <ChatMessageInput
                getImageBlob={getImageBlob}
                isPublicChatWindow={isPublicChatWindow}
                sendFeedbackFunc={sendFeedbackFunc}
                setData={value}
                updateInput={updateInput}
                auth={auth}
                showDetailModal={showDetailModal}
                setUpdateInput={setUpdateInput}
                setScrollIntoView={setScrollIntoView}
                isInterviewCanvas={isInterviewCanvas}
              />
              {/* <div className='chat-message-input-box'>
                <textarea
                  className='chat-message-input'
                  placeholder={'Message...'}
                  onKeyUp={keyDownHandler}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                  style={{ height: value.length === 0 ? '40px' : '' }}
                  ref={inputField}
                  value={value}
                  rows={1}
                  id={'resizable-input'}
                  onPaste={(e) => getImageBlob(e)}
                  autoFocus={isPublicChatWindow ? false : true}
                />

                <div className='send-feedback-button chat-window-feedback-button'>
                  <div
                    style={{ cursor: value !== '' ? 'pointer' : 'not-allowed' }}
                    onClick={value !== '' ? sendFeedbackFunc : () => null}
                  >
                    <SendMessageIcon color={value !== '' ? '#306AE1' : '#B0BEC5'} />
                  </div>
                </div>
              </div> */}
              <div className='message-seprator'>
                { /* !auth.isEmpty && (
                  <Popover
                    content={
                      isRecordingModal ? (
                        <RecordAudio
                          maxRecordTime={recordVoiceClipTime}
                          projectID={projectID}
                          setLoading={() => updateFeedbackFileSelect(1)}
                          onClose={() => setIsRecordingModal(false)}
                          showPercentage
                          onSubmit={onAudioSubmit}
                          background={theme.backgrounds.color2}
                          profile={profile}
                          auth={auth}
                          organization={organization}
                        />
                      ) : null
                    }
                    // title="Title"
                    trigger='click'
                    visible={isRecordingModal}
                  //   onVisibleChange={(audioPopoverVisible: boolean) => {
                  //     if(audioPopoverVisible){
                  //       setIsRecordingModal(true)
                  //     }
                  //   }
                  // }
                  >
                    {!isRecordingModal && (
                      <Tooltip
                        placement='top'
                        title={
                          userQuota && userQuota.storageLimit <= 0
                            ? 'Your current plan doesn’t include using this feature.'
                            : userQuota && userQuota.totalVoiceClips <= 0
                              ? 'Your current plan doesn’t include using this feature.'
                              : 'Send Audio'
                        }
                      >
                        <div
                          className={
                            !isWriteAccess ||
                              (userQuota.storageLimit <= 0 || userQuota.totalVoiceClips <= 0)
                              ? 'disabled'
                              : '' + ' c-p'
                          }
                          onClick={audioIconClick}
                        >
                          <AudioSvg />
                        </div>
                      </Tooltip>
                    )}
                    {isRecordingModal && <span onClick={audioIconClick}><AudioSvg /></span>}
                  </Popover>
                ) */ }
                <MediaPermissionModal isOpen={isOpenMediaPermissionModal} setIsOpen={setIsOpenMediaPermissionModal}/>
                { <Tooltip
                    placement='top'
                    title={'Record Audio'}
                  >
                  <div
                    className={'c-p'}
                    onClick={async () => {
                      if(!auth.isEmpty){
                        try {
                          // @ts-ignore
                          window.stream = await getStream();
                          setSelectedChatOption('audio');
                        } catch (err) {
                          console.error('error in audio permission:', err);
                          setIsOpenMediaPermissionModal(true);
                          return false;
                          // Issue getting mic
                        }
                      }else{
                        showDetailModal();
                      }
                    }}
                  >
                    <AudioSvg />
                  </div>
                </Tooltip> }
                {!auth.isEmpty && (
                  //  !isRecordingModal &&
                  <Popover
                    content={
                      <RecordVideo
                        updateFeedback={updateFeedbackFileSelect}
                        isRecordModal={isRecordModal}
                        setIsRecordModal={setIsRecordModal}
                        sendFeedback={sendFeedback}
                        pageID={pageID}
                        sendVideoToStore={sendVideoToStore}
                        setSendingImage={() => null}
                        userQuota={userQuota}
                        isPublicChatWindow={isPublicChatWindow}
                        firebaseAuthToken={firebaseAuthToken}
                        profile={profile}
                        auth={auth}
                        organization={organization}
                      />
                    }
                    // title="Title"
                    trigger='click'
                    visible={isRecordModal === 'video'}
                  // onVisibleChange={
                  //   !isWriteAccess ||
                  //   (userQuota.storageLimit <= 0 || userQuota.totalVideoComments <= 0)
                  //     ? () => null
                  //     : isRecordModal.length > 0
                  //     //? () => setIsRecordModal('')
                  //     ? () => null
                  //     : videoIconClick
                  // }
                  >
                    {!(isRecordModal === 'video') && (
                      <Tooltip
                        placement='top'
                        title={
                          userQuota.storageLimit <= 0
                            ? 'Your current plan doesn’t include using this feature.'
                            : userQuota.totalVideoComments <= 0
                              ? 'Your current plan doesn’t include using this feature.'
                              : 'Send Video'
                        }
                      >
                        <div
                          className={
                            !isWriteAccess ||
                              (userQuota.storageLimit <= 0 || userQuota.totalVideoComments <= 0)
                              ? 'disabled'
                              : '' + ' c-p'
                          }
                          onClick={() => {
                            !(!isWriteAccess ||
                              (userQuota.storageLimit <= 0 || userQuota.totalVideoComments <= 0))
                              && videoIconClick()
                          }}
                        >
                          <VideoSvg />
                        </div>
                      </Tooltip>
                    )}
                    {isRecordModal === 'video' && (
                      <div
                        className={
                          !isWriteAccess ||
                            (userQuota.storageLimit <= 0 || userQuota.totalVideoComments <= 0)
                            ? 'disabled'
                            : '' + ' c-p'
                        }
                        onClick={videoIconClick}
                      >
                        <VideoSvg />
                      </div>
                    )}
                  </Popover>
                )}

                {!auth.isEmpty && !isMobile && (
                  //  !isRecordingModal &&
                  <Popover
                    content={
                      isRecordModal ? (
                        <RecordVideo
                          updateFeedback={updateFeedbackFileSelect}
                          isRecordModal={isRecordModal}
                          setIsRecordModal={setIsRecordModal}
                          sendFeedback={sendFeedback}
                          pageID={pageID}
                          sendVideoToStore={sendVideoToStore}
                          setSendingImage={() => null}
                          userQuota={userQuota}
                          isPublicChatWindow={isPublicChatWindow}
                          firebaseAuthToken={firebaseAuthToken}
                          profile={profile}
                          auth={auth}
                          organization={organization}
                        />
                      ) : null
                    }
                    // title="Title"
                    trigger='click'
                    visible={isRecordModal === 'screen'}
                  // onVisibleChange={
                  //   !isWriteAccess ||
                  //   (userQuota.storageLimit <= 0 || userQuota.totalScreenRecordings <= 0)
                  //     ? () => null
                  //     : isRecordModal.length > 0
                  //     //? () => setIsRecordModal('')
                  //     ? () => null
                  //     : screenIconClick
                  // }
                  >
                    {!(isRecordModal === 'screen') && (
                      <Tooltip
                        placement='top'
                        title={
                          userQuota.storageLimit <= 0
                            ? 'Your current plan doesn’t include using this feature.'
                            : userQuota.totalScreenRecordings <= 0
                              ? 'Your current plan doesn’t include using this feature.'
                              : 'Send Screen Recording'
                        }
                      >
                        <div
                          className={
                            !isWriteAccess ||
                              (userQuota.storageLimit <= 0 || userQuota.totalScreenRecordings <= 0)
                              ? 'disabled'
                              : '' + ' c-p'
                          }
                          onClick={() => {
                            !(!isWriteAccess ||
                              (userQuota.storageLimit <= 0 || userQuota.totalScreenRecordings <= 0)) && screenIconClick()
                          }
                          }
                        >
                          <ScreenSvg />
                        </div>
                      </Tooltip>
                    )}
                    {isRecordModal === 'screen' && (
                      <div
                        className={
                          !isWriteAccess ||
                            (userQuota.storageLimit <= 0 || userQuota.totalScreenRecordings <= 0)
                            ? 'disabled'
                            : '' + ' c-p'
                        }
                        onClick={screenIconClick}
                      >
                        <ScreenSvg />
                      </div>
                    )}
                  </Popover>
                )}

                {!auth.isEmpty && (
                  // !isRecordingModal &&
                  <div className={!isWriteAccess || userQuota.storageLimit <= 0 ? `disabled` : ''}>
                    <Tooltip
                      placement='topLeft'
                      title={
                        userQuota.storageLimit <= 0
                          ? 'Your current plan doesn’t include using this feature.'
                          : 'Upload File'
                      }
                    >
                      <svg
                        className={!isWriteAccess ? 'disabled' : '' + ' c-p'}
                        onClick={uploadIconClick}
                        width='14'
                        height='16'
                        viewBox='0 0 14 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M7.77778 11.2111V2.97767L10.3389 5.5388L11.4389 4.43886L7 0L2.56114 4.43886L3.66108 5.5388L6.22222 2.97767V11.2111H7.77778ZM14 14.3222V7.32217H12.4444V14.3222H1.55556V7.32217H0V14.3222C0 15.1813 0.696446 15.8777 1.55556 15.8777H12.4444C13.3036 15.8777 14 15.1813 14 14.3222Z'
                          fill='#4F4F4F'
                        />
                      </svg>
                      <input
                        type='file'
                        ref={inputFileRef}
                        className='hideElement'
                        onChange={(e) => {
                          isWriteAccess && userQuota.storageLimit > 0 && fileChangeHandler(e);
                        }}
                        multiple={true}
                        disabled={!isWriteAccess || userQuota.storageLimit <= 0}
                      />
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
          )}

          {/* </DragAndDropZone> */}
          <UploadModal
            isUploadModal={isUploadModal}
            setIsUploadModal={setIsUploadModal}
            sendFeedback={sendFeedback}
            pageID={pageID}
            userID={userID}
          />
          <CommentInfoModal
            setPreview={() => setShowCommentInfoModal(false)}
            showPreview={showCommentInfoModal}
            data={dataForCommentInfo}
          />
          <SideDrawerComponent
            visible={showPreview}
            ChildComponent={() => <ImagePreviewComponent selectedImage={selectedImage} />}
            onClose={() => setPreview(false)}
            screenCoverage={80}
            direction={'right'}
          />

          <SideDrawerComponent
            visible={showCinemaModal !== ''}
            ChildComponent={() => (
              //   <RenderVideos
              //   isMediaCreatedByLoginUser={isMediaCreatedByLoginUser}
              //   createdTime={createdAt}
              //   blobURL={blobURL}
              //   videos={[videoUrl]}
              //   parentComponent='projectDetails'
              //   pauseVideo={pauseVideo}
              //   changeThread={changeThread}
              //   selctedThreadMediaTime={selctedThreadMediaTime}
              // />

              <RenderVideosFile
                isMediaCreatedByLoginUser={true}
                createdTime={playerData.createdTime}
                blobURL={playerData.blobURL}
                videos={[playerData.videoURL]}
                iframeClass={playerData.iframeClass}
                parentComponent='projectDetails'
                isTheaterMode
              />
            )}
            onClose={() => setShowCinemaModal('')}
            screenCoverage={80}
            direction={'right'}
            isNotFlex={true}
          />
        </React.Fragment>
      )}
    </ColorContext.Consumer>
  );
};
const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    pages: state.firestore.data.pages,
    iframe: state.project.iframe,
    firebaseAuthToken: state.auth.firebaseAuthToken,
    organization: state.organization,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    updateFirebaseAuthToken: (firebaseAuthToken: string) =>
      dispatch(updateFirebaseAuthTokenAction(firebaseAuthToken)),
    // addPageFeedback: (pageComment: any, data: any, pageId: string) =>
    //   dispatch(addPageComment(pageComment, data, pageId)),
    resolvePageFeedback: (circleId: string, pages: any, iframe: any) =>
      dispatch(resolvePage(circleId, pages, iframe)),
  };
};

export default withRouter(compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(FeedbackModel) as any) as any;
