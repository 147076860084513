import { Progress, Typography } from 'antd';
import React from 'react';
import ColorContext from '../../context/ColorContext';
// import { Close } from '../svg';
import './style.css';

interface IProps {
  organizations: any;
}

const UserQuota: React.FC<IProps> = ({ organizations }) => {
  const selectedOrganizationId = window.localStorage.getItem('selectedOrganizationId');
  const selectedOrganizationMatchedIndex = organizations.findIndex(
    (og: any) => og.id === selectedOrganizationId,
  );
  const organization = organizations[selectedOrganizationMatchedIndex];
  const { userQuota = {}, plan = '' } = organization;
  const {
    maxChromeExtensionScreenshots = 0,
    chromeExtensionScreenshots = 0,
    storageLimit = 0,
    maxStorageLimit = 0,
    totalVoiceClips = 0,
    maxTotalVoiceClips = 0,
    totalVideoComments = 0,
    maxTotalVideoComments = 0,
    totalScreenRecordings = 0,
    maxTotalScreenRecordings = 0,
    canvasesCount = '',
    maxCanvasesCount = '',
  } = userQuota;
  const getPlanLabel = (planKey: string) => {
    let planLabel = '';
    switch (planKey) {
      case 'freelancer':
        planLabel = 'Freelancers Plan Feature';
        break;
      case 'productTeam':
        planLabel = 'Product Teams Plan Feature';
        break;
      case 'digitalAgency':
        planLabel = 'Digital Agencies Plan Features';
        break;
    }
    return planLabel;
  };
  return (
    <div>
      <ColorContext.Consumer>
        {({ theme }) => (
          <div className='edit-profile-userquota-container' style={{ color: theme.text.color2 }}>
            <div>
              <Typography.Text strong={true} className='settings-form-label'>
                {getPlanLabel(plan)}
              </Typography.Text>
              {/* {maxChromeExtensionScreenshots && ( */}
                <div className='pt-5'>
                  <div className='user-quota-details pt-5 user-quota-progress-container'>
                    <span className='display-block'>Chrome Extension Screenshot Count: </span>
                    <span color='#039BE5'>
                      {' '}
                      {maxChromeExtensionScreenshots === -1
                        ? 'Unlimited'
                        : `${maxChromeExtensionScreenshots -
                            chromeExtensionScreenshots} / ${maxChromeExtensionScreenshots}`}
                    </span>
                  </div>
                  {maxChromeExtensionScreenshots !== -1 ? (
                    <Progress
                      percent={
                        ((maxChromeExtensionScreenshots - chromeExtensionScreenshots) /
                          maxChromeExtensionScreenshots) *
                        100
                      }
                      showInfo={false}
                    />
                  ) : null}
                </div>
              {/* )} */}

              <div className='pt-5'>
                <div className='user-quota-details pt-5 user-quota-progress-container'>
                  <span className='display-block'>Storage Limit:</span>
                  <span color='#039BE5'>
                    {' '}
                    {maxStorageLimit
                      ? storageLimit === -1
                        ? 'Unlimited'
                        : `${((maxStorageLimit - storageLimit) / (1024 * 1024)).toFixed(2)} / ${(
                            maxStorageLimit /
                            (1024 * 1024)
                          ).toFixed(0)} GB`
                      : '0 GB'}
                  </span>
                </div>
                {maxStorageLimit !== -1 ? (
                  <Progress
                    percent={((maxStorageLimit - storageLimit) / maxStorageLimit) * 100}
                    showInfo={false}
                  />
                ) : null}
              </div>

              <div className='pt-5'>
                <div className='user-quota-details pt-5 user-quota-progress-container'>
                  <span className='display-block'>Voice Clips Limit:</span>
                  <span color='#039BE5'>
                    {' '}
                    {maxTotalVoiceClips
                      ? maxTotalVoiceClips === -1
                        ? 'Unlimited'
                        : `${maxTotalVoiceClips - totalVoiceClips} / ${maxTotalVoiceClips}`
                      : 0}
                  </span>
                </div>
                {maxTotalVoiceClips !== -1 ? (
                  <Progress
                    percent={((maxTotalVoiceClips - totalVoiceClips) / maxTotalVoiceClips) * 100}
                    showInfo={false}
                  />
                ) : null}
              </div>

              <div className='pt-5'>
                <div className='user-quota-details pt-5 user-quota-progress-container'>
                  <span className='display-block'>Video Comments Limit:</span>
                  <span color='#039BE5'>
                    {' '}
                    {maxTotalVideoComments
                      ? maxTotalVideoComments === -1
                        ? 'Unlimited'
                        : `${maxTotalVideoComments - totalVideoComments} / ${maxTotalVideoComments}`
                      : 0}
                  </span>
                </div>
                {maxTotalVideoComments !== -1 ? (
                  <Progress
                    percent={
                      ((maxTotalVideoComments - totalVideoComments) / maxTotalVideoComments) * 100
                    }
                    showInfo={false}
                  />
                ) : null}
              </div>

              <div className='pt-5'>
                <div className='user-quota-details pt-5 user-quota-progress-container'>
                  <span className='display-block'>Screen Recordings Limit:</span>
                  <span color='#039BE5'>
                    {' '}
                    {maxTotalScreenRecordings
                      ? maxTotalScreenRecordings === -1
                        ? 'Unlimited'
                        : `${maxTotalScreenRecordings -
                            totalScreenRecordings} / ${maxTotalScreenRecordings}`
                      : 0}
                  </span>
                </div>
                {maxTotalScreenRecordings !== -1 ? (
                  <Progress
                    percent={
                      ((maxTotalScreenRecordings - totalScreenRecordings) /
                        maxTotalScreenRecordings) *
                      100
                    }
                    showInfo={false}
                  />
                ) : null}
              </div>

              {/* {maxCanvasesCount && ( */}
                <div className='pt-5'>
                  <div className='user-quota-details pt-5 user-quota-progress-container'>
                    <span className='display-block'>Canvas Count:</span>
                    <span color='#039BE5'>
                      {' '}
                      {canvasesCount === -1
                        ? 'Unlimited'
                        : `${maxCanvasesCount - canvasesCount} / ${maxCanvasesCount}`}
                    </span>
                  </div>
                  {maxCanvasesCount !== -1 ? (
                    <Progress
                      percent={((maxCanvasesCount - canvasesCount) / maxCanvasesCount) * 100}
                      showInfo={false}
                    />
                  ) : null}
                </div>
               {/* )} */}
            </div>
          </div>
        )}
      </ColorContext.Consumer>
    </div>
  );
};

export default UserQuota;
