import { Modal } from 'antd';
import React from 'react';

interface CommentInfoMapper {
  title?: string;
  data: any;
  showPreview: boolean;
  setPreview: () => void;
}

export const CommentInfoModal = ({ title, data, showPreview, setPreview }: CommentInfoMapper) => {
  return (
    <Modal
      title={ title || 'Navigator Information'}
      visible={showPreview}
      style={{ textAlign: 'center' }}
      onCancel={setPreview}
      footer={null}
    >
      <div style={{textAlign: 'left'}}>{data}</div>
    </Modal>
  );
};
